import React from "react";
import MainWrap from "./MainWrap";
import LatestNews from "./LatestNews";

const settings = {
  name: "News and Views",
  img1: "news-333.jpg",
  title: "News from Bijan Air, Inc.",
  heading: "News",
  subheadIndex: 2,
  subheadArrow: "Latest"
};

const NewsWrap = ({ data, node, location }) => {
  const Subheader = props => (
    <LatestNews {...props} data={data.allMarkdownRemark.edges} />
  );

  return (
    <MainWrap Subheader={Subheader} settings={settings} location={location}>
      <div key={node.id}>
        <h2>{node.frontmatter.title}</h2>
        <p>
          {node.frontmatter.date} &mdash;{" "}
          <strong>{node.frontmatter.intro}</strong>
        </p>
        <div dangerouslySetInnerHTML={{ __html: node.html }} />
      </div>
    </MainWrap>
  );
};

export default NewsWrap;
