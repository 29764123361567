import React from "react";
import { graphql } from "gatsby";
import NewsWrap from "../components/NewsWrap";

const NewsItem = ({ data, location }) => (
  <NewsWrap data={data} node={data.markdownRemark} location={location} />
);

export default NewsItem;

export const query = graphql`
  query NewsItemQuery($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        date(formatString: "MMM DD, YYYY")
        intro
      }
    }

    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fileAbsolutePath: { regex: "/(news)/[^/]+[.]md$/" } }
      limit: 2
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            intro
            date(formatString: "MMM DD, YYYY")
          }
          html
        }
      }
    }
  }
`;
