import React from "react";
import SubHead from "./SubHead/";
import Link from "gatsby-link";

const LatestNews = ({ settings, data }) => (
  <SubHead settings={settings}>
    <ul id="latest_list">
      {data.map(({ node }) => (
        <li key={node.id}>
          <Link to={node.fields.slug}>{node.frontmatter.title}</Link>
          <p>
            <i>{node.frontmatter.date}</i> &mdash; {node.frontmatter.intro}
          </p>
        </li>
      ))}
    </ul>
  </SubHead>
);

export default LatestNews;
